import { defineStore } from 'pinia';

interface PageState {
  routeData: any;
}

export const routeQuery = `
  query route($url: String!) {
    route(url: $url) {
      type
       ... on ProductInterface {
        sku
      }
      ... on CategoryTree {
        id
        uid
        children {
            uid
            name
        }
        name
      }
      ... on CmsPage {
        identifier
      }
    }
  }
`;

export const usePageStore = defineStore('page', {
  state: (): PageState => ({
    routeData: null,
  }),
});
