import { Middleware } from '@nuxt/types';
import { usePageStore, routeQuery } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { RoutableInterface } from '~/modules/GraphQL/types';

const urlResolverMiddleware : Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '')
    .replace(/feed\//gi, '')
    .replace(/addToCart\//gi, '');

  Logger.debug('middleware/url-resolver', clearUrl);
  
  const { data, errors } = await context.app.$vsf.$magento.api.customQuery({
    query: routeQuery,
    queryVariables: {
      url: clearUrl
    }
  });
  
  Logger.debug('middleware/url-resolver/result', { data, errors });

  const results: RoutableInterface | null = (data as any)?.route ?? null;

  if (!results || errors?.length) context.error({ statusCode: 404 });

  try {
    if (results?.type == 'CATEGORY') {
      const categoryList = await context.app.$vsf.$magento.api.categoryList()
      const hotTopicsCategory = categoryList.data.categories.items[0].children.find(el => el.name.toLowerCase() == 'temi caldi')
      const hotTopicsUid = hotTopicsCategory.uid
      const hotTopicsChildrenUids = hotTopicsCategory.children.map(el=>el.uid)
      if (hotTopicsUid == results.uid) {
        //@ts-ignore
        results.type = 'HOT_TOPICS'
      } else if (hotTopicsChildrenUids.includes(results.uid)) {
        //@ts-ignore
        results.type = 'HOT_TOPIC'
      }
    }
  } catch (e) {
    Logger.warn('No Hot Topics Category or Hot Topics Children')
  }
    

  pageStore.$patch((state) => {
    state.routeData = results;
  });
};

export default urlResolverMiddleware;
