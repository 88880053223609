import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_0409066c from 'nuxt_plugin_plugin_0409066c' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_nuxtplugin27f4d1b1_0f8adb3e from 'nuxt_plugin_nuxtplugin27f4d1b1_0f8adb3e' // Source: ./nuxt.plugin.27f4d1b1.js (mode: 'server')
import nuxt_plugin_vuescrollto_59c8eb91 from 'nuxt_plugin_vuescrollto_59c8eb91' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_412d19c0 from 'nuxt_plugin_cookieuniversalnuxt_412d19c0' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_175109c3 from 'nuxt_plugin_pluginutils_175109c3' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_6b82dc52 from 'nuxt_plugin_pluginseo_6b82dc52' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_3d36324e from 'nuxt_plugin_pluginrouting_3d36324e' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_39228be1 from 'nuxt_plugin_pluginmain_39228be1' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_distplugin57527824_268e2af4 from 'nuxt_plugin_distplugin57527824_268e2af4' // Source: ./dist.plugin.57527824.mjs (mode: 'all')
import nuxt_plugin_image_2bbcc073 from 'nuxt_plugin_image_2bbcc073' // Source: ./image.js (mode: 'all')
import nuxt_plugin_coreplugin2b8990cb_4a18cd03 from 'nuxt_plugin_coreplugin2b8990cb_4a18cd03' // Source: ./core.plugin.2b8990cb.ts (mode: 'all')
import nuxt_plugin_pluginse2etesting38f658eb_83494cac from 'nuxt_plugin_pluginse2etesting38f658eb_83494cac' // Source: ./plugins.e2e-testing.38f658eb.js (mode: 'all')
import nuxt_plugin_deviceplugin_70ee6303 from 'nuxt_plugin_deviceplugin_70ee6303' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_6c233a94 from 'nuxt_plugin_workbox_6c233a94' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_d99aa4b2 from 'nuxt_plugin_metaplugin_d99aa4b2' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_tokenexpired_ca1e20ca from 'nuxt_plugin_tokenexpired_ca1e20ca' // Source: ../plugins/token-expired (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_fcPlugin_5a846bc6 from 'nuxt_plugin_fcPlugin_5a846bc6' // Source: ../plugins/fcPlugin (mode: 'all')
import nuxt_plugin_dompurify_9e093c4a from 'nuxt_plugin_dompurify_9e093c4a' // Source: ../plugins/dompurify (mode: 'all')
import nuxt_plugin_storeConfigPlugin_234467e0 from 'nuxt_plugin_storeConfigPlugin_234467e0' // Source: ../plugins/storeConfigPlugin (mode: 'all')
import nuxt_plugin_soleclientplugins_117ffc60 from 'nuxt_plugin_soleclientplugins_117ffc60' // Source: ../plugins/sole-client-plugins (mode: 'client')
import nuxt_plugin_meta_1d50025c from 'nuxt_plugin_meta_1d50025c' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Il Sole 24 ORE","htmlAttrs":{"lang":"it"},"meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Il Sole 24 Ore"},{"name":"theme-color","content":"#f5e5d5"},{"name":"msapplication-TileColor","content":"#ffddbb"},{"name":"generator","content":"Vue Storefront 2"}],"script":[{"src":"https:\u002F\u002Fj2.res.24o.it\u002Fjs\u002F2020\u002Fsolecp.js?v2.0","async":false,"defer":false},{"src":"https:\u002F\u002Fcdn.tailwindcss.com"},{"src":"\u002Fresources\u002Fscripts\u002Fvwo.js","id":"vwoCode","strategy":"beforeInteractive","type":"text\u002Fjavascript"},{"type":"module","src":"https:\u002F\u002Fcdn.scalapay.com\u002Fwidget\u002Fv3\u002Fjs\u002Fscalapay-widget.esm.js"},{"nomodule":true,"src":"https:\u002F\u002Fcdn.scalapay.com\u002Fwidget\u002Fv3\u002Fjs\u002Fscalapay-widget.js"}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Fdev.visualwebsiteoptimizer.com"}],"style":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_0409066c === 'function') {
    await nuxt_plugin_plugin_0409066c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin27f4d1b1_0f8adb3e === 'function') {
    await nuxt_plugin_nuxtplugin27f4d1b1_0f8adb3e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_59c8eb91 === 'function') {
    await nuxt_plugin_vuescrollto_59c8eb91(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_412d19c0 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_412d19c0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_175109c3 === 'function') {
    await nuxt_plugin_pluginutils_175109c3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_6b82dc52 === 'function') {
    await nuxt_plugin_pluginseo_6b82dc52(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_3d36324e === 'function') {
    await nuxt_plugin_pluginrouting_3d36324e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_39228be1 === 'function') {
    await nuxt_plugin_pluginmain_39228be1(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin57527824_268e2af4 === 'function') {
    await nuxt_plugin_distplugin57527824_268e2af4(app.context, inject)
  }

  if (typeof nuxt_plugin_image_2bbcc073 === 'function') {
    await nuxt_plugin_image_2bbcc073(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin2b8990cb_4a18cd03 === 'function') {
    await nuxt_plugin_coreplugin2b8990cb_4a18cd03(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting38f658eb_83494cac === 'function') {
    await nuxt_plugin_pluginse2etesting38f658eb_83494cac(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_70ee6303 === 'function') {
    await nuxt_plugin_deviceplugin_70ee6303(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_6c233a94 === 'function') {
    await nuxt_plugin_workbox_6c233a94(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_d99aa4b2 === 'function') {
    await nuxt_plugin_metaplugin_d99aa4b2(app.context, inject)
  }

  if (typeof nuxt_plugin_tokenexpired_ca1e20ca === 'function') {
    await nuxt_plugin_tokenexpired_ca1e20ca(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_fcPlugin_5a846bc6 === 'function') {
    await nuxt_plugin_fcPlugin_5a846bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_dompurify_9e093c4a === 'function') {
    await nuxt_plugin_dompurify_9e093c4a(app.context, inject)
  }

  if (typeof nuxt_plugin_storeConfigPlugin_234467e0 === 'function') {
    await nuxt_plugin_storeConfigPlugin_234467e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_soleclientplugins_117ffc60 === 'function') {
    await nuxt_plugin_soleclientplugins_117ffc60(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_1d50025c === 'function') {
    await nuxt_plugin_meta_1d50025c(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
