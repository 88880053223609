








import { defineComponent, useContext } from '@nuxtjs/composition-api';
import Error from '~/components/404Error.vue';
import SoleproError from '~/pages/solepro/Error.vue';
import { SOLEPRO_STORE_CODE } from '~/utils/constants/store';
import { getLayout } from '~/utils/helpers/page';

export default defineComponent({
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  components:{
    Error,
    SoleproError
  },
  layout(context) {
    return getLayout(context.i18n.locale);
  },
  setup(){
    const {i18n} = useContext();
    const ErrorComponent = i18n.locale == SOLEPRO_STORE_CODE ? SoleproError:Error;
    return {
      ErrorComponent
    }
  }
});
