const scriptsEnvPrefix = process.env.SOLE_ENV !== 'prod' ? 'qa' : 'prod';

export const PAYWALL_TITLE = 'Abbonamenti - Il Sole 24 ORE';
export const PAYWALL_TITLE_RENEW = 'Il Tuo Abbonamento Il Sole 24 Ore sta scadendo? Rinnova Ora!';

export const PAYWALL_DESCRIPTION_RENEW = 'Il tuo abbonamento a Il Sole 24 Ore sta scadendo? Rinnova subito per non perdere i tuoi contenuti! Offerte esclusive e possibilità di pagamento rateale.'

export const PAYWALL_SCRIPTS = [
  { src: '/resources/tailwind-sole.presets.js' },
  { src: 'https://cdn.jsdelivr.net/npm/swiper@11/swiper-element-bundle.min.js', body: true },
  // --> @TODO: Loaded with middleware to avoid property emptyness, maybe we should use it for other stuff?
  // { type: 'text/javascript', src: `//tags.tiqcdn.com/utag/ilsole24ore/shopping/${scriptsEnvPrefix}/utag.sync.js` },
  // { type: 'text/javascript', body: true, src: `/resources/scripts/trackings/sh24/utag-${scriptsEnvPrefix}.js` },
];

export const PAYWALL_LINKS = [
  { rel: 'stylesheet', as: 'style', href: 'https://c2.res.24o.it/fonts/sole-sans/sole-sans.css' },
  { rel: 'stylesheet', as: 'style', href: 'https://c.res.24o.it/fonts/w-fonts.css' },
  { rel: 'stylesheet', as: 'style', href: 'https://c.res.24o.it/fonts/w-fonts.css' },
  { rel: 'icon', type: 'image/png', href: '/resources/favicons/favicon-32x32.png', sizes: '32x32', },
  { rel: 'icon', type: 'image/png', href: '/resources/favicons/favicon-16x16.png', sizes: '16x16', },
  { rel: 'apple-touch-icon', href: '/resources/favicons/icon-180x180.jpg' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-57x57.jpg', sizes: '57x57' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-60x60.jpg', sizes: '60x60' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-72x72.jpg', sizes: '72x72' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-76x76.jpg', sizes: '76x76' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-114x114.jpg', sizes: '114x114' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-120x120.jpg', sizes: '120x120' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-144x144.jpg', sizes: '144x144' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-152x152.jpg', sizes: '167x167' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-180x180.jpg', sizes: '180x180' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/icon-1024x1024.jpg', sizes: '1024x1024' },
  { rel: 'manifest', href: '/resources/site.webmanifest' },
  { rel: 'mask-icon', href: '/resources/safari-pinned-tab.svg', color: '#003c7f' },
];

export const SOLEPRO_SCRIPTS = [
  { src: '/resources/tailwind-solepro.presets.js' },
  // --> @TODO: Loaded with middleware to avoid property emptyness, maybe we should use it for other stuff?
  // { type: 'text/javascript', src: `//tags.tiqcdn.com/utag/ilsole24ore/24pro/${scriptsEnvPrefix}/utag.sync.js` },
  // { type: 'text/javascript', body: true, src: `/resources/scripts/trackings/24pro/utag-${scriptsEnvPrefix}.js` },
];

export const SOLEPRO_LINKS = [
  { rel: 'stylesheet', as: 'style', href: 'https://use.typekit.net/paf5kqa.css' },
  { rel: 'shortcut icon', type: 'image/png', href: '/resources/favicons/solepro/favicon-60x60.png' },
  { rel: 'icon', type: 'image/x-icon', href: '/resources/favicons/solepro/favicon-32x32.png' },
  { rel: 'icon', type: 'image/png', href: '/resources/favicons/solepro/favicon-32x32.png', sizes: '32x32', },
  { rel: 'icon', type: 'image/png', href: '/resources/favicons/solepro/favicon-32x32.png', sizes: '32x32', },
  { rel: 'icon', type: 'image/png', href: '/resources/favicons/solepro/favicon-16x16.png', sizes: '16x16', },
  { rel: 'apple-touch-icon', href: '/resources/favicons/solepro/icon-180x180.jpg' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-57x57.jpg', sizes: '57x57' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-60x60.jpg', sizes: '60x60' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-72x72.jpg', sizes: '72x72' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-76x76.jpg', sizes: '76x76' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-114x114.jpg', sizes: '114x114' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-120x120.jpg', sizes: '120x120' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-144x144.jpg', sizes: '144x144' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-152x152.jpg', sizes: '167x167' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-180x180.jpg', sizes: '180x180' },
  { rel: 'apple-touch-startup-image', href: '/resources/favicons/solepro/icon-1024x1024.jpg', sizes: '1024x1024' },
  { rel: 'manifest', href: '/resources/solepro/site.webmanifest' },
  { rel: 'mask-icon', href: '/resources/solepro/safari-pinned-tab.svg', color: '#ffffff' },
];

export const SOLEPRO_TITLE = '24 Ore Professionale';
