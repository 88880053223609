










import { defineComponent } from '@nuxtjs/composition-api'
import { PAYWALL_LINKS, PAYWALL_SCRIPTS } from '~/utils/constants/nuxt/metaInfos';

export default defineComponent({
    metaInfo: {
    script: PAYWALL_SCRIPTS,
    link: PAYWALL_LINKS,
  },
})
