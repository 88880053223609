import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _44a38858 = () => interopDefault(import('../components/AddToCart.vue' /* webpackChunkName: "" */))
const _ac50eeaa = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _cdca585a = () => interopDefault(import('../pages/solepro/Cart.vue' /* webpackChunkName: "" */))
const _e503b35e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _27a262c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _ea43beb0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _4d358908 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7a19f1ba = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _90e710f8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _6feb273d = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _334aaf70 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _bdb2f244 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _a0b36474 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _51c00fda = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _51c4725e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _34025bdc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _614cbb1e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _0a594199 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _e4866bac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _39e18bf0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _27318dd6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _812f9bb4 = () => interopDefault(import('../pages/Product/index.vue' /* webpackChunkName: "" */))
const _0bf0faec = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _0545df92 = () => interopDefault(import('../pages/solepro/Hub.vue' /* webpackChunkName: "" */))
const _81d36564 = () => interopDefault(import('../pages/Landing/index.vue' /* webpackChunkName: "pages/Landing/index" */))
const _0dbf6ac9 = () => interopDefault(import('../pages/solepro/Librerie.vue' /* webpackChunkName: "" */))
const _2b770807 = () => interopDefault(import('../pages/Maintenance.vue' /* webpackChunkName: "pages/Maintenance" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _36494cbf = () => interopDefault(import('../pages/Rinnovi.vue' /* webpackChunkName: "" */))
const _1fe0fc34 = () => interopDefault(import('../pages/solepro/Cms.vue' /* webpackChunkName: "pages/solepro/Cms" */))
const _d85c6436 = () => interopDefault(import('../pages/solepro/Error.vue' /* webpackChunkName: "pages/solepro/Error" */))
const _7bbc5612 = () => interopDefault(import('../pages/solepro/Home.vue' /* webpackChunkName: "pages/solepro/Home" */))
const _5c091878 = () => interopDefault(import('../pages/solepro/HotTopicPage.vue' /* webpackChunkName: "pages/solepro/HotTopicPage" */))
const _d4764646 = () => interopDefault(import('../pages/solepro/HotTopicsPage.vue' /* webpackChunkName: "pages/solepro/HotTopicsPage" */))
const _145e0d8f = () => interopDefault(import('../pages/solepro/Product/index.vue' /* webpackChunkName: "pages/solepro/Product/index" */))
const _5d291f9b = () => interopDefault(import('../pages/solepro/Search.vue' /* webpackChunkName: "pages/solepro/Search" */))
const _1a0d618a = () => interopDefault(import('../pages/solepro/Product/_slug/index.vue' /* webpackChunkName: "pages/solepro/Product/_slug/index" */))
const _32cf6f16 = () => interopDefault(import('../pages/Landing/_utmCampaign/index.vue' /* webpackChunkName: "pages/Landing/_utmCampaign/index" */))
const _69b978e1 = () => interopDefault(import('../pages/Product/_slug/index.vue' /* webpackChunkName: "pages/Product/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/24orepro",
    component: _115548ca,
    name: "home___24orepro"
  }, {
    path: "/addToCart",
    component: _44a38858,
    props: (route) => {
      const skus = route.query.sku.split(',');
      const modulesSkus = { [skus[0]]: route.query.moduli ? route.query.moduli.split(',') : null }; // this is used only for one product at a time by design, we cannot differ this kind of qs because of the retrocompatibility
      const tipoAbbonamento = {};
      skus.forEach((sku) => {tipoAbbonamento[sku] = route.query.tipoAbbonamento;});
      const tipoConsegna = {};
      skus.forEach((sku) => {tipoConsegna[sku] = route.query.tipoConsegna;});
      return {
        fromRoute: true,
        skus,
        getProductBefore: true,
        modulesSkus,
        tipoAbbonamento,
        tipoConsegna,
        utmCampaign: route.query.utm_campaign
      };
    },
    name: "addToCart___paywall"
  }, {
    path: "/cart",
    component: _ac50eeaa,
    name: "cart___paywall"
  }, {
    path: "/cart.html",
    component: _cdca585a,
    name: "solepro-cart___paywall"
  }, {
    path: "/checkout",
    component: _e503b35e,
    name: "checkout___paywall",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___paywall"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___paywall"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___paywall"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___paywall"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___paywall"
    }]
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms___paywall"
  }, {
    path: "/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___paywall",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___paywall"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___paywall"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___paywall"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___paywall"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___paywall"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___paywall"
    }, {
      path: "/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___paywall"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___paywall"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___paywall"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___paywall"
    }]
  }, {
    path: "/detailProduct",
    component: _812f9bb4,
    props: (route) => ({
      slug: route.query.slug ? route.query.slug : '',
      sku: route.query.sku
    }),
    name: "detailProduct___paywall"
  }, {
    path: "/Faq",
    component: _0bf0faec,
    name: "Faq___paywall"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___paywall"
  }, {
    path: "/hub.html",
    component: _0545df92,
    name: "hub___paywall"
  }, {
    path: "/Landing",
    component: _81d36564,
    name: "Landing___paywall"
  }, {
    path: "/librerie.html",
    component: _0dbf6ac9,
    name: "librerie___paywall"
  }, {
    path: "/Maintenance",
    component: _2b770807,
    name: "Maintenance___paywall"
  }, {
    path: "/mbooks",
    component: _115548ca,
    name: "home___mbooks"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___paywall"
  }, {
    path: "/prodotti",
    component: _36494cbf,
    name: "rinnovi_prodotti___paywall"
  }, {
    path: "/Product",
    component: _812f9bb4,
    name: "Product___paywall"
  }, {
    path: "/rinnovi",
    component: _115548ca,
    name: "home___rinnovi"
  }, {
    path: "/Rinnovi",
    component: _36494cbf,
    name: "Rinnovi___paywall"
  }, {
    path: "/24orepro/addToCart",
    component: _44a38858,
    props: (route) => {
      const skus = route.query.sku.split(',');
      const modulesSkus = { [skus[0]]: route.query.moduli ? route.query.moduli.split(',') : null }; // this is used only for one product at a time by design, we cannot differ this kind of qs because of the retrocompatibility
      const tipoAbbonamento = {};
      skus.forEach((sku) => {tipoAbbonamento[sku] = route.query.tipoAbbonamento;});
      const tipoConsegna = {};
      skus.forEach((sku) => {tipoConsegna[sku] = route.query.tipoConsegna;});
      return {
        fromRoute: true,
        skus,
        getProductBefore: true,
        modulesSkus,
        tipoAbbonamento,
        tipoConsegna,
        utmCampaign: route.query.utm_campaign
      };
    },
    name: "addToCart___24orepro"
  }, {
    path: "/24orepro/cart",
    component: _ac50eeaa,
    name: "cart___24orepro"
  }, {
    path: "/24orepro/cart.html",
    component: _cdca585a,
    name: "solepro-cart___24orepro"
  }, {
    path: "/24orepro/checkout",
    component: _e503b35e,
    name: "checkout___24orepro",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___24orepro"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___24orepro"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___24orepro"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___24orepro"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___24orepro"
    }]
  }, {
    path: "/24orepro/Cms",
    component: _6feb273d,
    name: "Cms___24orepro"
  }, {
    path: "/24orepro/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___24orepro",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___24orepro"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___24orepro"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___24orepro"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___24orepro"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___24orepro"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___24orepro"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___24orepro"
    }, {
      path: "/24orepro/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___24orepro"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___24orepro"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___24orepro"
    }]
  }, {
    path: "/24orepro/detailProduct",
    component: _812f9bb4,
    props: (route) => ({
      slug: route.query.slug ? route.query.slug : '',
      sku: route.query.sku
    }),
    name: "detailProduct___24orepro"
  }, {
    path: "/24orepro/Faq",
    component: _0bf0faec,
    name: "Faq___24orepro"
  }, {
    path: "/24orepro/Home",
    component: _115548ca,
    name: "Home___24orepro"
  }, {
    path: "/24orepro/hub.html",
    component: _0545df92,
    name: "hub___24orepro"
  }, {
    path: "/24orepro/Landing",
    component: _81d36564,
    name: "Landing___24orepro"
  }, {
    path: "/24orepro/librerie.html",
    component: _0dbf6ac9,
    name: "librerie___24orepro"
  }, {
    path: "/24orepro/Maintenance",
    component: _2b770807,
    name: "Maintenance___24orepro"
  }, {
    path: "/24orepro/Page",
    component: _478a714b,
    name: "Page___24orepro"
  }, {
    path: "/24orepro/prodotti",
    component: _36494cbf,
    name: "rinnovi_prodotti___24orepro"
  }, {
    path: "/24orepro/Product",
    component: _812f9bb4,
    name: "Product___24orepro"
  }, {
    path: "/24orepro/Rinnovi",
    component: _36494cbf,
    name: "Rinnovi___24orepro"
  }, {
    path: "/mbooks/addToCart",
    component: _44a38858,
    props: (route) => {
      const skus = route.query.sku.split(',');
      const modulesSkus = { [skus[0]]: route.query.moduli ? route.query.moduli.split(',') : null }; // this is used only for one product at a time by design, we cannot differ this kind of qs because of the retrocompatibility
      const tipoAbbonamento = {};
      skus.forEach((sku) => {tipoAbbonamento[sku] = route.query.tipoAbbonamento;});
      const tipoConsegna = {};
      skus.forEach((sku) => {tipoConsegna[sku] = route.query.tipoConsegna;});
      return {
        fromRoute: true,
        skus,
        getProductBefore: true,
        modulesSkus,
        tipoAbbonamento,
        tipoConsegna,
        utmCampaign: route.query.utm_campaign
      };
    },
    name: "addToCart___mbooks"
  }, {
    path: "/mbooks/cart",
    component: _ac50eeaa,
    name: "cart___mbooks"
  }, {
    path: "/mbooks/cart.html",
    component: _cdca585a,
    name: "solepro-cart___mbooks"
  }, {
    path: "/mbooks/checkout",
    component: _e503b35e,
    name: "checkout___mbooks",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___mbooks"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___mbooks"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___mbooks"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___mbooks"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___mbooks"
    }]
  }, {
    path: "/mbooks/Cms",
    component: _6feb273d,
    name: "Cms___mbooks"
  }, {
    path: "/mbooks/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___mbooks",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___mbooks"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___mbooks"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___mbooks"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___mbooks"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___mbooks"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___mbooks"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___mbooks"
    }, {
      path: "/mbooks/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___mbooks"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___mbooks"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___mbooks"
    }]
  }, {
    path: "/mbooks/detailProduct",
    component: _812f9bb4,
    props: (route) => ({
      slug: route.query.slug ? route.query.slug : '',
      sku: route.query.sku
    }),
    name: "detailProduct___mbooks"
  }, {
    path: "/mbooks/Faq",
    component: _0bf0faec,
    name: "Faq___mbooks"
  }, {
    path: "/mbooks/Home",
    component: _115548ca,
    name: "Home___mbooks"
  }, {
    path: "/mbooks/hub.html",
    component: _0545df92,
    name: "hub___mbooks"
  }, {
    path: "/mbooks/Landing",
    component: _81d36564,
    name: "Landing___mbooks"
  }, {
    path: "/mbooks/librerie.html",
    component: _0dbf6ac9,
    name: "librerie___mbooks"
  }, {
    path: "/mbooks/Maintenance",
    component: _2b770807,
    name: "Maintenance___mbooks"
  }, {
    path: "/mbooks/Page",
    component: _478a714b,
    name: "Page___mbooks"
  }, {
    path: "/mbooks/prodotti",
    component: _36494cbf,
    name: "rinnovi_prodotti___mbooks"
  }, {
    path: "/mbooks/Product",
    component: _812f9bb4,
    name: "Product___mbooks"
  }, {
    path: "/mbooks/Rinnovi",
    component: _36494cbf,
    name: "Rinnovi___mbooks"
  }, {
    path: "/rinnovi/addToCart",
    component: _44a38858,
    props: (route) => {
      const skus = route.query.sku.split(',');
      const modulesSkus = { [skus[0]]: route.query.moduli ? route.query.moduli.split(',') : null }; // this is used only for one product at a time by design, we cannot differ this kind of qs because of the retrocompatibility
      const tipoAbbonamento = {};
      skus.forEach((sku) => {tipoAbbonamento[sku] = route.query.tipoAbbonamento;});
      const tipoConsegna = {};
      skus.forEach((sku) => {tipoConsegna[sku] = route.query.tipoConsegna;});
      return {
        fromRoute: true,
        skus,
        getProductBefore: true,
        modulesSkus,
        tipoAbbonamento,
        tipoConsegna,
        utmCampaign: route.query.utm_campaign
      };
    },
    name: "addToCart___rinnovi"
  }, {
    path: "/rinnovi/cart",
    component: _ac50eeaa,
    name: "cart___rinnovi"
  }, {
    path: "/rinnovi/cart.html",
    component: _cdca585a,
    name: "solepro-cart___rinnovi"
  }, {
    path: "/rinnovi/checkout",
    component: _e503b35e,
    name: "checkout___rinnovi",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___rinnovi"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___rinnovi"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___rinnovi"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___rinnovi"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___rinnovi"
    }]
  }, {
    path: "/rinnovi/Cms",
    component: _6feb273d,
    name: "Cms___rinnovi"
  }, {
    path: "/rinnovi/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___rinnovi",
    children: [{
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___rinnovi"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___rinnovi"
    }, {
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___rinnovi"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___rinnovi"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___rinnovi"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___rinnovi"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___rinnovi"
    }, {
      path: "/rinnovi/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___rinnovi"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___rinnovi"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___rinnovi"
    }]
  }, {
    path: "/rinnovi/detailProduct",
    component: _812f9bb4,
    props: (route) => ({
      slug: route.query.slug ? route.query.slug : '',
      sku: route.query.sku
    }),
    name: "detailProduct___rinnovi"
  }, {
    path: "/rinnovi/Faq",
    component: _0bf0faec,
    name: "Faq___rinnovi"
  }, {
    path: "/rinnovi/Home",
    component: _115548ca,
    name: "Home___rinnovi"
  }, {
    path: "/rinnovi/hub.html",
    component: _0545df92,
    name: "hub___rinnovi"
  }, {
    path: "/rinnovi/Landing",
    component: _81d36564,
    name: "Landing___rinnovi"
  }, {
    path: "/rinnovi/librerie.html",
    component: _0dbf6ac9,
    name: "librerie___rinnovi"
  }, {
    path: "/rinnovi/Maintenance",
    component: _2b770807,
    name: "Maintenance___rinnovi"
  }, {
    path: "/rinnovi/Page",
    component: _478a714b,
    name: "Page___rinnovi"
  }, {
    path: "/rinnovi/prodotti",
    component: _36494cbf,
    name: "rinnovi_prodotti___rinnovi"
  }, {
    path: "/rinnovi/Product",
    component: _812f9bb4,
    name: "Product___rinnovi"
  }, {
    path: "/rinnovi/Rinnovi",
    component: _36494cbf,
    name: "Rinnovi___rinnovi"
  }, {
    path: "/solepro/Cart",
    component: _cdca585a,
    name: "solepro-Cart___paywall"
  }, {
    path: "/solepro/Cms",
    component: _1fe0fc34,
    name: "solepro-Cms___paywall"
  }, {
    path: "/solepro/Error",
    component: _d85c6436,
    name: "solepro-Error___paywall"
  }, {
    path: "/solepro/Home",
    component: _7bbc5612,
    name: "solepro-Home___paywall"
  }, {
    path: "/solepro/HotTopicPage",
    component: _5c091878,
    name: "solepro-HotTopicPage___paywall"
  }, {
    path: "/solepro/HotTopicsPage",
    component: _d4764646,
    name: "solepro-HotTopicsPage___paywall"
  }, {
    path: "/solepro/Hub",
    component: _0545df92,
    name: "solepro-Hub___paywall"
  }, {
    path: "/solepro/Librerie",
    component: _0dbf6ac9,
    name: "solepro-Librerie___paywall"
  }, {
    path: "/solepro/Product",
    component: _145e0d8f,
    name: "solepro-Product___paywall"
  }, {
    path: "/solepro/Search",
    component: _5d291f9b,
    name: "solepro-Search___paywall"
  }, {
    path: "/24orepro/solepro/Cart",
    component: _cdca585a,
    name: "solepro-Cart___24orepro"
  }, {
    path: "/24orepro/solepro/Cms",
    component: _1fe0fc34,
    name: "solepro-Cms___24orepro"
  }, {
    path: "/24orepro/solepro/Error",
    component: _d85c6436,
    name: "solepro-Error___24orepro"
  }, {
    path: "/24orepro/solepro/Home",
    component: _7bbc5612,
    name: "solepro-Home___24orepro"
  }, {
    path: "/24orepro/solepro/HotTopicPage",
    component: _5c091878,
    name: "solepro-HotTopicPage___24orepro"
  }, {
    path: "/24orepro/solepro/HotTopicsPage",
    component: _d4764646,
    name: "solepro-HotTopicsPage___24orepro"
  }, {
    path: "/24orepro/solepro/Hub",
    component: _0545df92,
    name: "solepro-Hub___24orepro"
  }, {
    path: "/24orepro/solepro/Librerie",
    component: _0dbf6ac9,
    name: "solepro-Librerie___24orepro"
  }, {
    path: "/24orepro/solepro/Product",
    component: _145e0d8f,
    name: "solepro-Product___24orepro"
  }, {
    path: "/24orepro/solepro/Search",
    component: _5d291f9b,
    name: "solepro-Search___24orepro"
  }, {
    path: "/mbooks/solepro/Cart",
    component: _cdca585a,
    name: "solepro-Cart___mbooks"
  }, {
    path: "/mbooks/solepro/Cms",
    component: _1fe0fc34,
    name: "solepro-Cms___mbooks"
  }, {
    path: "/mbooks/solepro/Error",
    component: _d85c6436,
    name: "solepro-Error___mbooks"
  }, {
    path: "/mbooks/solepro/Home",
    component: _7bbc5612,
    name: "solepro-Home___mbooks"
  }, {
    path: "/mbooks/solepro/HotTopicPage",
    component: _5c091878,
    name: "solepro-HotTopicPage___mbooks"
  }, {
    path: "/mbooks/solepro/HotTopicsPage",
    component: _d4764646,
    name: "solepro-HotTopicsPage___mbooks"
  }, {
    path: "/mbooks/solepro/Hub",
    component: _0545df92,
    name: "solepro-Hub___mbooks"
  }, {
    path: "/mbooks/solepro/Librerie",
    component: _0dbf6ac9,
    name: "solepro-Librerie___mbooks"
  }, {
    path: "/mbooks/solepro/Product",
    component: _145e0d8f,
    name: "solepro-Product___mbooks"
  }, {
    path: "/mbooks/solepro/Search",
    component: _5d291f9b,
    name: "solepro-Search___mbooks"
  }, {
    path: "/rinnovi/solepro/Cart",
    component: _cdca585a,
    name: "solepro-Cart___rinnovi"
  }, {
    path: "/rinnovi/solepro/Cms",
    component: _1fe0fc34,
    name: "solepro-Cms___rinnovi"
  }, {
    path: "/rinnovi/solepro/Error",
    component: _d85c6436,
    name: "solepro-Error___rinnovi"
  }, {
    path: "/rinnovi/solepro/Home",
    component: _7bbc5612,
    name: "solepro-Home___rinnovi"
  }, {
    path: "/rinnovi/solepro/HotTopicPage",
    component: _5c091878,
    name: "solepro-HotTopicPage___rinnovi"
  }, {
    path: "/rinnovi/solepro/HotTopicsPage",
    component: _d4764646,
    name: "solepro-HotTopicsPage___rinnovi"
  }, {
    path: "/rinnovi/solepro/Hub",
    component: _0545df92,
    name: "solepro-Hub___rinnovi"
  }, {
    path: "/rinnovi/solepro/Librerie",
    component: _0dbf6ac9,
    name: "solepro-Librerie___rinnovi"
  }, {
    path: "/rinnovi/solepro/Product",
    component: _145e0d8f,
    name: "solepro-Product___rinnovi"
  }, {
    path: "/rinnovi/solepro/Search",
    component: _5d291f9b,
    name: "solepro-Search___rinnovi"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___paywall"
  }, {
    path: "/24orepro/solepro/Product/:slug",
    component: _1a0d618a,
    name: "solepro-Product-slug___24orepro"
  }, {
    path: "/mbooks/solepro/Product/:slug",
    component: _1a0d618a,
    name: "solepro-Product-slug___mbooks"
  }, {
    path: "/rinnovi/solepro/Product/:slug",
    component: _1a0d618a,
    name: "solepro-Product-slug___rinnovi"
  }, {
    path: "/24orepro/feed/:slug+",
    component: _478a714b,
    name: "mbooks_feed___24orepro"
  }, {
    path: "/24orepro/Landing/:utmCampaign",
    component: _32cf6f16,
    name: "Landing-utmCampaign___24orepro"
  }, {
    path: "/24orepro/Product/:slug",
    component: _69b978e1,
    name: "Product-slug___24orepro"
  }, {
    path: "/24orepro/search/:term?",
    component: _5d291f9b,
    props: (route) => ({
      term: route.params.term ? route.params.term : '',
      isSearch: true
    }),
    name: "search___24orepro"
  }, {
    path: "/mbooks/feed/:slug+",
    component: _478a714b,
    name: "mbooks_feed___mbooks"
  }, {
    path: "/mbooks/Landing/:utmCampaign",
    component: _32cf6f16,
    name: "Landing-utmCampaign___mbooks"
  }, {
    path: "/mbooks/Product/:slug",
    component: _69b978e1,
    name: "Product-slug___mbooks"
  }, {
    path: "/mbooks/search/:term?",
    component: _5d291f9b,
    props: (route) => ({
      term: route.params.term ? route.params.term : '',
      isSearch: true
    }),
    name: "search___mbooks"
  }, {
    path: "/rinnovi/feed/:slug+",
    component: _478a714b,
    name: "mbooks_feed___rinnovi"
  }, {
    path: "/rinnovi/Landing/:utmCampaign",
    component: _32cf6f16,
    name: "Landing-utmCampaign___rinnovi"
  }, {
    path: "/rinnovi/Product/:slug",
    component: _69b978e1,
    name: "Product-slug___rinnovi"
  }, {
    path: "/rinnovi/search/:term?",
    component: _5d291f9b,
    props: (route) => ({
      term: route.params.term ? route.params.term : '',
      isSearch: true
    }),
    name: "search___rinnovi"
  }, {
    path: "/solepro/Product/:slug",
    component: _1a0d618a,
    name: "solepro-Product-slug___paywall"
  }, {
    path: "/24orepro/:slug+",
    component: _478a714b,
    name: "page___24orepro"
  }, {
    path: "/feed/:slug+",
    component: _478a714b,
    name: "mbooks_feed___paywall"
  }, {
    path: "/Landing/:utmCampaign",
    component: _32cf6f16,
    name: "Landing-utmCampaign___paywall"
  }, {
    path: "/mbooks/:slug+",
    component: _478a714b,
    name: "page___mbooks"
  }, {
    path: "/Product/:slug",
    component: _69b978e1,
    name: "Product-slug___paywall"
  }, {
    path: "/rinnovi/:slug+",
    component: _478a714b,
    name: "page___rinnovi"
  }, {
    path: "/search/:term?",
    component: _5d291f9b,
    props: (route) => ({
      term: route.params.term ? route.params.term : '',
      isSearch: true
    }),
    name: "search___paywall"
  }, {
    path: "/:slug+",
    component: _478a714b,
    name: "page___paywall"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
