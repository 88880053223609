











import { defineComponent } from '@nuxtjs/composition-api';
import { SOLEPRO_LINKS, SOLEPRO_SCRIPTS } from '~/utils/constants/nuxt/metaInfos';

export default defineComponent({
  name: 'ErrorPage',
  metaInfo:{
    script: SOLEPRO_SCRIPTS,
    link: SOLEPRO_LINKS
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  }
});
